<template>
	<div
			class="dropzone-container"
			@dragover="dragover"
			@dragleave="dragleave"
			@drop="drop">
		<input
				type="file"
				:multiple="maxLength > 1"
				:name="name"
				:id="name"
				class="hidden-input"
				@change="onChange"
				:ref="name"
				:accept="acceptFiles" />

		<label :for="name" class="cursor-pointer">
			<slot name="button"></slot>
		</label>

		<template v-if="!hideField">
			<label :for="name" :class="`file-label${isDragging ? ' is-dragging' : ''} d-flex align-items-center justify-content-between ${simple ? 'border-dark border-1' : 'fs-16px'}`">
				<span v-if="!simple">Перенесите файл сюда или выберите из диска</span>
				<span class="text-decoration-underline" v-if="!simple">Обзор</span>
				<span class="d-flex align-items-center justify-content-between" v-if="defaultFileName && !files.length">
				<IconDocuments :width="18" :height="18" />
				<span class="ms-12px me-auto w-150px overflow-hidden text-overflow-ellipsis" :title="defaultFileName">
					{{ defaultFileName }}
				</span>
			</span>
				<span class="text-decoration-underline" v-if="simple && !files.length">{{defaultFileName ? 'Прикрепить другой файл' : 'Прикрепить файл'}}</span>
				<span class="d-flex align-items-center justify-content-between" v-if="files.length && simple" v-for="file in files" :key="file.name">
				<IconDocuments :width="18" :height="18" />
				<span class="ms-12px me-auto" :title="file.name">
					{{ file.name }}
				</span>
			</span>
			</label>

			<div class="mt-12px text-gray-600" v-if="!simple">Максимум {{maxLength > 1 ? maxLength : 'один'}} {{$plural(maxLength, 'файл', 'файла', 'файлов')}}. Доступные форматы: {{acceptFilesNames}}</div>

			<div class="preview-container" v-if="files.length && !simple">
				<div v-for="file in files" :key="file.name" class="preview-card mt-12px d-flex align-items-center justify-content-between">
					<IconDocuments />
					<div class="ms-12px me-auto" :title="file.name">
						{{ file.name }}
					</div>
					<a href="#" @click.prevent="remove(files.indexOf(file))">
						<IconTrash color="#FF0000" />
					</a>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
  import IconDocuments from '@/components/icons/IconDocuments'
  import IconTrash from '@/components/icons/IconTrash'

  export default {
    name: 'InputFile',
    components: { IconTrash, IconDocuments },
    props: {
      'maxLength': {
        type: Number,
        default: 1,
      },
      'acceptFiles': {
        type: String,
        default: '.pdf,.rar,.zip',
      },
      'button': {},
	  'hideField': {
        type: Boolean,
        default: false,
      },
      'simple': {
        type: Boolean,
        default: false,
      },
      'name': {
        type: String,
        default: 'InputFile',
      },
      'defaultFileName': {
        type: String,
        default: '',
      },

    },
    data() {
      return {
        isDragging: false,
        files: [],
      }
    },
    computed: {
      acceptFilesNames() {
        return this.acceptFiles.replace(/\./g, '').split(',').join(', ')
      },
    },
    methods: {
      onChange() {
        if (Number(this.maxLength) === 1) {
          this.files = [...this.$refs[this.name].files]
        } else {
          this.files = [...this.files, ...this.$refs[this.name].files]
        }
        this.$emit('setFiles', this.files)
      },

      makeName(name) {
        return (
          name.split('.')[0].substring(0, 40) +
          '...' +
          name.split('.')[name.split('.').length - 1]
        )
      },

      getExtension(filename) {
        let parts = filename.split('.')
        return parts[parts.length - 1]
      },

      remove(i) {
        this.files.splice(i, 1)
      },

      dragover(e) {
        e.preventDefault()
        this.isDragging = true
      },

      dragleave() {
        this.isDragging = false
      },

      drop(e) {
        e.preventDefault()

        this.isDragging = false

        let error = false

        e.dataTransfer.files.forEach(file => {
          if (this.acceptFiles.indexOf(this.getExtension(file.name)) < 0) {
            error = true
            this.$notify({
              group: 'center',
              title: `Ошибка!`,
              text: `Файл ${file.name} имеет недопустимый формат`,
              type: 'error',
            })
          }
        })
        if (e.dataTransfer.files.length > this.maxLength) {
          error = true
          this.$notify({
            group: 'center',
            title: `Ошибка!`,
            text: 'Количество файлов превышает допустимое!',
            type: 'error',
          })
        }
        if (error) return
        this.$refs[this.name].files = e.dataTransfer.files
        this.onChange()
      },
    },
  }
</script>

<style>
	.hidden-input {
		opacity: 0;
		overflow: hidden;
		position: absolute;
		width: 1px;
		height: 1px;
	}
	.file-label {
		cursor: pointer;
		border: 2px solid #F5F5F5;
		padding: 8px 16px;
		border-radius: 6px;
	}
	.file-label.border-dark {
		border-color: #868685 !important;
	}
	.file-label.is-dragging {
		border-color: #00B9E4
	}
</style>
