import axios from 'axios'

export default {
  async fetchTicketsItems({ rootState, commit }, params) {
    try {
      if (!rootState.auth.token) return { error: true }

      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'contact_person':
            path = `/contact_person/tickets/list`
            break
          case 'sales_department_manager':
            path = `/mop/tickets/list`
            break
          case 'head_department_manager':
            path = `/rop/tickets/list`
            break
        }
      })

      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
        data: {
          page: params.page || 1,
        },
      })

      if (!res.data.success && res.data.message) {
        commit('toast/NEW', { type: 'error', message: res.data.message }, { root: true })
      } else {
        commit('SET_TICKETS_ITEMS', { page: params.page || 1, ...res.data })
      }

      return res.data ? res.data : res.error
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  async fetchTicketsItem({ rootState, commit }, id) {
    try {
      if (!rootState.auth.token) return { error: true }

      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'contact_person':
            path = `/contact_person/tickets/${id}/get`
            break
          case 'sales_department_manager':
            path = `/mop/tickets/${id}/get`
            break
          case 'head_department_manager':
            path = `/rop/tickets/${id}/get`
            break
        }
      })

      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
      })

      if (!res.data.success && res.data.message) {
        commit('toast/NEW', { type: 'error', message: res.data.message }, { root: true })
      } else {
        commit('SET_TICKETS_ITEM', res.data.data)
      }

      return res.data ? res.data : res.error
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  async fetchTicketsItemMessages({ rootState, commit }, params) {
    try {
      if (!rootState.auth.token) return { error: true }

      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'contact_person':
            path = `/contact_person/tickets/${params.id}/messages/list`
            break
          case 'sales_department_manager':
            path = `/mop/tickets/${params.id}/messages/list`
            break
          case 'head_department_manager':
            path = `/rop/tickets/${params.id}/messages/list`
            break
        }
      })

      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
        data: {
          page: params.page
        }
      })

      if (!res.data.success && res.data.message) {
        commit('toast/NEW', { type: 'error', message: res.data.message }, { root: true })
      } else {
        commit('SET_MESSAGES_TO_TICKETS_ITEM', { id_ticket: params.id, page: params.page, ...res.data.data  })
      }

      return res.data ? res.data : res.error
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  async fetchTicketsItemSendMessage({ rootState, commit }, params) {
    try {
      if (!rootState.auth.token) return { error: true }

      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'contact_person':
            path = `/contact_person/tickets/${params.id}/messages/add`
            break
          case 'sales_department_manager':
            path = `/mop/tickets/${params.id}/messages/add`
            break
          case 'head_department_manager':
            path = `/rop/tickets/${params.id}/messages/add`
            break
        }
      })

      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
        data: params.data
      })

      if (!res.data.success && res.data.message) {
        commit('toast/NEW', { type: 'error', message: res.data.message }, { root: true })
      } else {
        commit('SET_MESSAGE_TO_TICKETS_ITEM', { id_ticket: params.id, item: res.data.data })
      }

      return res.data ? res.data : res.error
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  reset({ rootState, commit }) {
    return commit('RESET_NOTIFICATIONS_ITEMS')
  },
}
